import React, { useEffect } from 'react';
import LoadingIcon from '../icons/LoadingIcon';

export default function Loading() {
  useEffect(() => {
    const loadingS = document.querySelector('#loading-screen');
    loadingS.style.opacity = 0;
    loadingS.style.visibility = 'hidden';
  }, []);

  return (
    <div id="loading-screen">
      <div className="loading-content">
        <div className="graphic">
          <LoadingIcon />
        </div>

        <div className="text-content">
          <div className="text-wrap">
            <span>Loading themes</span>
            <span>Optimising images</span>
            <span>Styling texts</span>
            <span>Adding sound effects</span>

            <span>Loading themes</span>
            <span>Optimising images</span>
            <span>Styling texts</span>
            <span>Adding sound effects</span>
          </div>
        </div>
      </div>
    </div>
  );
}
