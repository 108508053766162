import { Howl } from 'howler';
import gsap from 'gsap';
import { heroReveal } from './onloadAnims';
import { mobileMenuHandler } from './menuHandler';
import SelectedSFX from '../sfx/selected.mp3';
// import Whoosh from '../sfx/whoosh1.mp3';

const selected = new Howl({
  src: [SelectedSFX],
});
// const whoosh = new Howl({
//   src: [Whoosh],
// });

// Theme open animation
export function themeOpenAnim(noBgAnim = false) {
  const themeScreen = document.querySelector('#theme-selector');
  const themeBg = themeScreen.querySelector('.theme-bg');
  const hLines = themeScreen.querySelectorAll('h2 .inner');
  const chooseText = themeScreen.querySelector('p.choose');
  const everyThemes = themeScreen.querySelectorAll('.theme');
  const closeBtn = themeScreen.querySelector('.close-btn');
  let smallDelay = 0;
  if (noBgAnim) {
    smallDelay = 1.4;
  }

  const tlOpen = gsap.timeline();

  if (closeBtn) {
    gsap.set(closeBtn, { opacity: 1, visibility: 'visible' });
  }
  gsap.set(themeScreen, { opacity: 1, visibility: 'visible' });
  if (!noBgAnim) {
    tlOpen.fromTo(
      themeBg,
      { y: '100%' },
      {
        duration: 0.8,
        y: 0,
        ease: 'power3.out',
      }
    );
  }
  tlOpen.fromTo(
    [hLines, chooseText, closeBtn],
    { y: '100%', opacity: 0 },
    {
      duration: 0.6,
      delay: smallDelay,
      y: 0,
      opacity: 1,
      stagger: 0.1,
      ease: 'power3.out',
    },
    '-=0.2'
  );
  tlOpen.fromTo(
    everyThemes,
    { opacity: 0, y: 40 },
    {
      duration: 0.6,
      opacity: 1,
      y: 0,
      stagger: 0.1,
      ease: 'power3.out',
    },
    '-=0.6'
  );
}

// Theme close animation
export function themeCloseAnim(closeBtn) {
  const themeScreen = document.querySelector('#theme-selector');
  const themeBg = themeScreen.querySelector('.theme-bg');
  const hLines = themeScreen.querySelectorAll('h2 .inner');
  const chooseText = themeScreen.querySelector('p.choose');
  const everyThemes = themeScreen.querySelectorAll('.theme');
  const tlClose = gsap.timeline();
  tlClose.fromTo(
    [hLines, chooseText, everyThemes, closeBtn],
    { y: 0, opacity: 1 },
    {
      duration: 0.4,
      y: 40,
      opacity: 0,
      ease: 'power3.out',
    }
  );
  tlClose.fromTo(
    themeBg,
    { y: 0 },
    {
      duration: 0.6,
      y: '100%',
      ease: 'power3.out',
    },
    '-=0.3'
  );
  tlClose.set(themeScreen, {
    opacity: 0,
    visibility: 'hidden',
  });
  tlClose.set(closeBtn, { y: 0, visibility: 'hidden' });
}

// Theme selector modal
export function openCloseTheme() {
  const themeLinks = document.querySelectorAll('.open-theme');
  const closeBtn = document.querySelector('#theme-selector .close-btn');

  themeLinks.forEach((themeLink) => {
    themeLink.addEventListener('click', () => {
      themeOpenAnim();
      document.querySelector('html').classList.add('modal-open');
    });
  });

  if (closeBtn) {
    closeBtn.addEventListener('click', () => {
      themeCloseAnim(closeBtn);
      document.querySelector('html').classList.remove('modal-open');
    });
  }
}

// When theme is selected
export function activatedBox(e, id, setActiveCardClass) {
  const themeScreen = document.querySelector('#theme-selector');
  const themeBg = themeScreen.querySelector('.theme-bg');
  const hLines = themeScreen.querySelectorAll('h2 .inner');
  const chooseText = themeScreen.querySelector('p.choose');
  const everyThemes = themeScreen.querySelectorAll('.theme');
  const closeBtn = themeScreen.querySelector('.close-btn');
  if (window.heroDelay === undefined) {
    window.heroDelay = 2;
  } else window.heroDelay = 2;

  if (closeBtn) {
    gsap.to(closeBtn, { duration: 0.4, opacity: 0 });
  }
  const tl = gsap.timeline();
  tl.add(() => heroReveal()); // hero reveal
  tl.add(() => mobileMenuHandler()); // hero reveal
  tl.add(() => selected.play()); // selected
  tl.fromTo(
    [hLines, chooseText],
    { y: 0, opacity: 1 },
    {
      duration: 0.6,
      y: '-100%',
      opacity: 0,
      stagger: 0.1,
      ease: 'power3.out',
    },
    '+=0.1'
  );
  // tl.set([hLines, chooseText], { display: 'none' }); // to center swiper
  tl.fromTo(
    everyThemes,
    { opacity: 1 },
    {
      duration: 0.4,
      opacity: 0,
      stagger: 0.08,
      ease: 'power3.out',
    },
    '-=0.6'
  );
  tl.set(everyThemes, { pointerEvents: 'none' });
  tl.set(e.currentTarget, {
    position: 'fixed',
    top: '50%',
    left: '50%',
    y: '-50%',
    x: '-50%',
  });
  tl.to(e.currentTarget, {
    duration: 0.1,
    opacity: 1,
    ease: 'power3.out',
  });
  tl.set(themeBg, { className: `theme-bg ${id}` });
  tl.to(
    e.currentTarget.querySelector('.decoratives'),
    {
      duration: 1.2,
      height: '120%',
      width: '120%',
      ease: 'power3.out',
    },
    '-=0.1'
  );
  tl.to(e.currentTarget, {
    duration: 0.4,
    y: '-60%',
    opacity: 0,
    ease: 'power3.out',
  });
  // tl.add(() => whoosh.play(), '-=0.7'); // whoosh
  tl.fromTo(
    themeBg,
    { y: 0 },
    {
      duration: 0.8,
      y: '-100%',
      ease: 'power3.out',
    },
    '-=0.3'
  );
  // Resetting
  tl.set('#theme-selector', { opacity: 0, visibility: 'hidden' });
  tl.set(themeBg, { className: 'theme-bg', y: 0 });
  tl.set(e.currentTarget, {
    position: 'initial',
    y: 0,
    x: 0,
  });
  tl.set(e.currentTarget.querySelector('.decoratives'), {
    height: '100%',
    width: '100%',
  });
  tl.set(everyThemes, { pointerEvents: 'auto' });
  // tl.set([hLines, chooseText], { display: 'block' });
  tl.call(setActiveCardClass, [null]);
  tl.add(() => document.querySelector('html').classList.remove('modal-open'));
}
