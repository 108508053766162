import gsap from 'gsap';

export function heroReveal() {
  let addedDelay = 0;
  if (window.heroDelay === undefined) {
    addedDelay = 0;
  } else addedDelay = window.heroDelay;

  if (document.querySelector('.hero')) {
    const hero = document.querySelector('.hero');

    // Hero with squiggles
    if (hero.classList.contains('hero-squiggle')) {
      const heroSec = document.querySelector('.hero.hero-squiggle');
      const socialLinks = heroSec.querySelectorAll('.social-links a');

      const squiggle1 = heroSec.querySelector(
        'span:first-child .squiggle1 path'
      );
      const length1 = squiggle1.getTotalLength();
      squiggle1.style.strokeDasharray = length1;
      squiggle1.style.strokeDashoffset = length1;

      const squiggle2 = heroSec.querySelector(
        'span:last-child .squiggle2 path'
      );
      const length2 = squiggle2.getTotalLength();
      squiggle2.style.strokeDasharray = length2;
      squiggle2.style.strokeDashoffset = length2;

      const tlSquiggle = gsap.timeline();
      tlSquiggle.fromTo(
        squiggle1,
        { strokeDashoffset: length1, opacity: 0 },
        {
          strokeDashoffset: 0,
          opacity: 1,
          duration: 1.4,
          delay: 0.5 + addedDelay,
          ease: 'power3.out',
        }
      );
      tlSquiggle.fromTo(
        squiggle2,
        { strokeDashoffset: length2, opacity: 0 },
        { strokeDashoffset: 0, opacity: 1, duration: 1, ease: 'power3.out' },
        '-=0.8'
      );
      // tl.fromTo(
      //   socialLinks,
      //   { opacity: 0, scale: 0.5 },
      //   {
      //     opacity: 1,
      //     scale: 1,
      //     duration: 1,
      //     stagger: 0.25,
      //     ease: 'elastic.out(1.25, 0.5)',
      //   },
      //   '-=0.8'
      // );
      // tl.fromTo(socialLinks, { scale: 0.99 }, { scale: 1, duration: 0.2 });
      if (socialLinks.length > 0) {
        for (let i = 0; i < socialLinks.length; i++) { // eslint-disable-line
          let moveX = 150 * (2 * i - 3);
          let moveY = 0;
          if (i === 0 || i === 3) {
            moveY = -50;
          }

          if (window.matchMedia('(max-width: 1080px)').matches) {
            moveX = 120 * (2 * i - 3);
          }
          if (window.matchMedia('(max-width: 800px)').matches) {
            moveY = 0;
            if (i === 2 || i === 3) {
              moveY = 200;
            }
            if (i === 0 || i === 2) {
              moveX = -100;
            } else moveX = 100;
          }
          if (window.matchMedia('(max-width: 600px)').matches) {
            moveY = 0;
            if (i === 2 || i === 3) {
              moveY = 180;
            }
            if (i === 0 || i === 2) {
              moveX = -90;
            } else moveX = 90;
          }

          tlSquiggle.fromTo(
            socialLinks[i],
            { top: 0, left: 0, opacity: 0, scale: 0.5 },
            {
              top: `${moveY}%`,
              left: `${moveX}%`,
              opacity: 1,
              scale: 1,
              duration: 0.8,
              ease: 'elastic.out(1.1, 0.9)',
            },
            '-=0.8'
          );
        }
      }
    } else {
      // General heroes with h1 lines
      const h1 = hero.querySelector('.show-up');
      const h1LineWraps = hero.querySelectorAll('h1 .line-wrap');
      const h1Lines = hero.querySelectorAll('h1 .line');
      const sub = hero.querySelectorAll('.subhead .line');
      const socials = hero.querySelectorAll('.social-links a');
      const caseInfo = hero.querySelectorAll('.info-data');

      const tlHero = gsap.timeline({ defaults: { ease: 'power3.out' } });
      if (h1Lines.length > 0) {
        tlHero.set(h1LineWraps, { overflow: 'hidden' });
        tlHero.fromTo(
          h1Lines,
          { y: '100%' },
          {
            duration: 0.8,
            delay: 0.3 + addedDelay,
            y: 0,
            stagger: 0.08,
          }
        );
        tlHero.set(h1LineWraps, { overflow: 'visible' }, '-=0.2');
      }
      if (h1) {
        tlHero.fromTo(
          h1,
          { y: 60, opacity: 0 },
          {
            duration: 0.8,
            delay: 0.3 + addedDelay,
            y: 0,
            opacity: 1,
          }
        );
      }
      if (sub.length > 0) {
        tlHero.fromTo(
          sub,
          {
            y: '100%',
          },
          {
            duration: 0.8,
            y: 0,
            stagger: 0.08,
          },
          '-=0.6'
        );
      }
      if (socials.length > 0) {
        tlHero.fromTo(
          socials,
          {
            y: 40,
            opacity: 0,
          },
          {
            duration: 0.8,
            y: 0,
            opacity: 1,
            stagger: 0.08,
          },
          '-=0.7'
        );
      }
      if (caseInfo.length > 0) {
        tlHero.fromTo(
          caseInfo,
          {
            y: 40,
            opacity: 0,
          },
          {
            duration: 0.8,
            y: 0,
            opacity: 1,
            stagger: 0.08,
          },
          '-=0.7'
        );
      }
    }
  }
}
