import { Howl } from 'howler';
import gsap from 'gsap';
import PageSFX1 from '../sfx/page1.mp3';
import PageSFX2 from '../sfx/page2.mp3';
import PageSFX3 from '../sfx/page3.mp3';
import Whoosh from '../sfx/whoosh1.mp3';

const sfx1 = new Howl({
  src: [PageSFX1],
});
const sfx2 = new Howl({
  src: [PageSFX2],
});
// const sfx3 = new Howl({
//   src: [PageSFX3],
// });
const whoosh = new Howl({
  src: [Whoosh],
});

export function pageAnim() {
  const transitionDiv = document.querySelector('#page-transition');
  const hasTheme =
    transitionDiv.classList.contains('theme1') ||
    transitionDiv.classList.contains('theme2') ||
    transitionDiv.classList.contains('theme3');
  const circles = transitionDiv.querySelectorAll('.circle');
  const boxes = transitionDiv.querySelectorAll('.box');
  const stripes = transitionDiv.querySelectorAll('.stripe');

  const transitionTl = gsap.timeline();
  if (hasTheme) {
    transitionTl.set(transitionDiv, { opacity: 1, visibility: 'visible' });

    if (transitionDiv.classList.contains('theme1')) {
      transitionTl.add(() => sfx1.play());
      transitionTl.fromTo(
        stripes,
        { y: '100%' },
        {
          duration: 0.8,
          y: 0,
          stagger: 0.12,
          ease: 'power3.out',
        },
        '+=0.04' // little delay due to page change glitch
      );
      transitionTl.add(() => whoosh.play());
      transitionTl.to(stripes, {
        duration: 0.6,
        y: '-100%',
        stagger: 0.1,
        ease: 'power3.out',
      });
    }

    if (transitionDiv.classList.contains('theme2')) {
      transitionTl.add(() => sfx2.play(), '-=0.1');
      transitionTl.fromTo(
        circles,
        { scale: 0.5, opacity: 0 },
        {
          duration: 0.8,
          scale: 1,
          opacity: 1,
          stagger: 0.12,
          ease: 'elastic.out(1.1, 0.9)',
        },
        '+=0.04' // little delay due to page change glitch
      );
      transitionTl.add(() => whoosh.play());
      transitionTl.to(circles, {
        duration: 0.6,
        scale: 0.5,
        opacity: 0,
        stagger: -0.08,
        ease: 'elastic.out(1.1, 0.9)',
      });
    }

    if (transitionDiv.classList.contains('theme3')) {
      transitionTl.add(() => sfx1.play());
      transitionTl.fromTo(
        boxes,
        { y: '100%' },
        {
          duration: 0.8,
          y: 0,
          stagger: 0.12,
          ease: 'power3.out',
        },
        '+=0.04' // little delay due to page change glitch
      );
      transitionTl.add(() => whoosh.play());
      transitionTl.to(boxes, {
        duration: 0.6,
        y: '-100%',
        stagger: -0.1,
        ease: 'power3.out',
      });
    }

    transitionTl.set(transitionDiv, { opacity: 0, visibility: 'hidden' });
  }
}
