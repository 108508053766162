import React from 'react';
import gsap from 'gsap';
import { AnimatePresence } from 'framer-motion';
import Loading from './src/components/Loading';
import PageTransition from './src/components/PageTransition';
import { themeOpenAnim } from './src/uitilities/themeHandlers';
import { pageAnim } from './src/uitilities/pageTransitions';

export const wrapPageElement = ({ element, props }) => (
  <>
    <Loading />
    <PageTransition {...props} />
    <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
  </>
);

export const onInitialClientRender = () => {
  if (document.querySelector('#loading-screen')) {
    const loadingTl = gsap.timeline();
    const loadingScreen = document.querySelector('#loading-screen');

    loadingTl.set(loadingScreen, {
      opacity: 1,
      visibility: 'visible',
    });
    loadingTl.fromTo(
      loadingScreen,
      { opacity: 1 },
      {
        duration: 0.8,
        opacity: 0,
        ease: 'power3.out',
      },
      '+=1'
    );
    loadingTl.set(loadingScreen, {
      visibility: 'hidden',
    });
  }

  const now = new Date().getTime();
  const expiryInHours = 168;
  const themeRekhEntry = localStorage.getItem('themeRekhEntry');
  if (themeRekhEntry === null) {
    localStorage.setItem('themeRekhEntry', now);
  } else if (now - themeRekhEntry > expiryInHours * 60 * 60 * 1000) {
    localStorage.removeItem('themeRekh');
    localStorage.setItem('themeRekhEntry', now);
  }

  if (!localStorage.getItem('themeRekh')) {
    themeOpenAnim(true);
  }

  window.heroDelay = 2;
};

export const onRouteUpdate = () => {
  pageAnim();
};
