import React from 'react';

export default function LoadingIcon() {
  return (
    <>
      <svg
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="13.7002"
          y="13.7002"
          width="24.6"
          height="24.6"
          stroke="white"
          strokeWidth="1"
          className="graphic-elem elem-1"
        />
        <circle
          cx="74"
          cy="26"
          r="16"
          fill="#713EFD"
          className="graphic-elem elem-2"
        />
        <path
          d="M25.9996 73.9996L40.3996 59.5996V88.3996H11.5996L25.9996 73.9996Z"
          fill="#F5C3B4"
          className="graphic-elem elem-3"
        />
        <path
          d="M90 74L74 58L58 74L74 90L90 74Z"
          fill="#BEFA19"
          className="graphic-elem elem-4"
        />
      </svg>
    </>
  );
}
