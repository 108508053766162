import React from 'react';

export default function PageTransition() {
  return (
    <div id="page-transition">
      {/* This component doesn't support variable/conditional content */}
      {/* We are adding class to #page-transition when theme is selected/changed */}

      <div className="transition-elements theme1">
        <div className="stripe stripe1" />
        <div className="stripe stripe2" />
        <div className="stripe stripe3" />
      </div>

      <div className="transition-elements theme2">
        <div className="circle circle1" />
        <div className="circle circle2" />
        <div className="circle circle3" />
        <div className="circle circle4" />
      </div>

      <div className="transition-elements theme3">
        <div className="box box1" />
        <div className="box box2" />
      </div>
    </div>
  );
}
