import gsap from 'gsap';

// Mobile menu
export function menuOpen1() {
  const menuScreen = document.querySelector('.mobile-menu');
  const menuBg = menuScreen.querySelector('.nav-bg');
  const menuBg1 = menuScreen.querySelector('.nav-bg-1');
  const menuBg2 = menuScreen.querySelector('.nav-bg-2');
  const links = menuScreen.querySelectorAll('.nav-links .link-contain > *');
  const close = menuScreen.querySelector('.close-btn');
  const socials = menuScreen.querySelectorAll('.social-links a');

  const tlOpen = gsap.timeline({ defaults: { ease: 'power3.inOut' } });

  gsap.set(menuScreen, { opacity: 1, visibility: 'visible' });
  gsap.set(close, { pointerEvents: 'none' });
  if (menuBg) {
    tlOpen.fromTo(
      menuBg,
      { y: '-100%' },
      {
        duration: 0.8,
        y: 0,
      }
    );
  }
  if (menuBg1 && menuBg2) {
    tlOpen.fromTo(
      [menuBg2, menuBg1],
      { y: '-100%' },
      {
        duration: 0.8,
        y: 0,
        stagger: 0.12,
      }
    );
  }
  tlOpen.fromTo(
    links,
    { y: '-100%' },
    {
      duration: 0.6,
      y: 0,
      stagger: 0.08,
    },
    '-=0.5'
  );
  tlOpen.fromTo(
    [close, socials],
    { y: -40, opacity: 0 },
    {
      duration: 0.6,
      y: 0,
      opacity: 1,
      stagger: 0.08,
    },
    '-=0.6'
  );
  tlOpen.set(close, { pointerEvents: 'auto' });
}
export function menuClose1() {
  const menuScreen = document.querySelector('.mobile-menu');
  const menuBg = menuScreen.querySelector('.nav-bg');
  const menuBg1 = menuScreen.querySelector('.nav-bg-1');
  const menuBg2 = menuScreen.querySelector('.nav-bg-2');
  const links = menuScreen.querySelectorAll('.nav-links .link-contain > *');
  const close = menuScreen.querySelector('.close-btn');
  const socials = menuScreen.querySelectorAll('.social-links a');

  const tlClose = gsap.timeline({ defaults: { ease: 'power3.inOut' } });

  tlClose.fromTo(
    links,
    { y: 0 },
    {
      duration: 0.4,
      y: '-100%',
    }
  );
  tlClose.fromTo(
    [close, socials],
    { opacity: 1 },
    {
      duration: 0.4,
      opacity: 0,
    },
    '0'
  );
  if (menuBg) {
    tlClose.fromTo(
      menuBg,
      { y: 0 },
      {
        duration: 0.6,
        y: '-100%',
      },
      '-=0.1'
    );
  }
  if (menuBg1 && menuBg2) {
    tlClose.fromTo(
      [menuBg1, menuBg2],
      { y: 0 },
      {
        duration: 0.6,
        y: '-100%',
        stagger: 0.08,
      },
      '-=0.1'
    );
  }
  tlClose.set(menuScreen, { opacity: 0, visibility: 'hidden' });
}

export function mobileMenuHandler() {
  if (document.querySelector('.open-menu')) {
    const openMenuLinks = document.querySelector('.open-menu');
    const mobileMenuDiv = document.querySelector('.mobile-menu');
    // const closeBtn = mobileMenuDiv.querySelector('.close-btn');
    const notActiveLinks = mobileMenuDiv.querySelectorAll(
      '.nav-links a:not(.active), .nav-links .open-theme, .close-btn'
    );

    openMenuLinks.addEventListener('click', () => {
      menuOpen1();
    });

    notActiveLinks.forEach((closeBtn) => {
      closeBtn.addEventListener('click', () => {
        menuClose1();
      });
    });
  }
}
